/**
 04. Plugins
**/
.slider{
    margin:0;
    padding: 0;
    li{
        padding: 20px 0;
        text-align: center;
    }
}
.flex-direction-nav a:before{
    font-family: "FontAwesome";
    content: "\f104";
}
.mfp-arrow-left:after{
        border:0;
        content:"\f104";
        display: inline;
        font-family: FontAwesome;
        color: #fff;
        font-size: 40px;
}
.mfp-arrow-right:after{
        border:0;
        content:"\f105";
        display: inline;
        font-family: FontAwesome;
        color: #fff;
        font-size: 40px;
}