/**
 03.5 Services
**/
.service-detail{
    .row{
        margin: 50px 0;
    }
    h3{
        margin-bottom: 30px;
    }
    h4{
        margin-bottom: 20px;
    }
    .pull-right{
        img{
            float: right;
        }
    } 
}
.service-item{
    text-align: center;
    margin-bottom: 50px;
    .icon{
        background: @base;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 45px;
        margin-bottom: 20px;
        .border-radius(80px);
    }
    h4{ text-transform: uppercase;}
} 




