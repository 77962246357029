
//Fonts
.opensans(){font-family: 'Open Sans', sans-serif;}
.raleway(){font-family: 'Raleway', sans-serif;}
.lato(){font-family: 'Lato', sans-serif;}

.transition(){
  -webkit-transition: all 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
}
.clearfix() {
  display: block;
  zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  -ms-border-radius:    @radius;
  -o-border-radius:    @radius;
  border-radius:         @radius;  
}


/**
 01.3 Misc
**/

section{
  padding: 70px 0;
}
 

.lazy-hidden {
    //background: #fff url('../images/ajax-loader.gif') no-repeat 50% 50%;
    opacity: 0;
}
.lazy-loaded {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 1;
}
