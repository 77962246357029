/**
 02.4 Info
**/

.alert{
    border-radius: 0;
    border:0;
}
.alert-success{
    background: @green;
    color: #fff;
}
.alert-info{
    background: @info;
    color: #fff;
}
.alert-warning{
    background: @orange;
    color: #fff;
}
.alert-danger{
    background: @base;
    color: #fff;
}

.error{
  color: #e74c3c;
  &.valid{
    color: #37a000;
  }
}
.form-control.error{
 border-color: #e74c3c; 
}

.pop-info{
    display: inline-block;
    text-align: center;
      position: absolute;
      left: 50%;
      margin-left: -60px;
      margin-top: -100px;
      padding: 10px;
      width: 120px;
      text-transform: uppercase;
      border-radius: 5px;
      background: #fff;
      color: @black;
      &:after{
        margin-bottom:-10px;
        position: absolute;
        bottom: 0;
        left: 43%;
        content:"";
        width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #ffffff transparent transparent transparent;
      }
      animation: upDown 12s 1s infinite;
}
@-webkit-keyframes upDown {
    60%, 80%, 100% { margin-top: -100px; }
    70%, 90% { margin-top: -140px; } 
}