/**
 02.2 Buttons
**/

.btn{
	border-radius: 0;
	border:0;
	color: #fff;
	padding:15px 20px;
	font-size: 16px;
	text-transform:uppercase;
	letter-spacing: 1px;
	.transition;
} 
.btn-default{ 
	background: @black;
	&:hover{
		background: darken(@black, 10%);
		color: #fff;
	}
	&.btn-border{
		color: @black;
		background: none;
		border:2px solid @black;
		&:hover{
			background: darken(@black, 10%);
			color: #fff;
			border:2px solid #426486;
		}
	}
}
.btn-primary{ 
	background: @base;
	&:hover{
		background: darken(@base, 20%);
		color: #fff;
	}
	&.btn-border{
		color: @base;
		background: none;
		border:2px solid @base;
		&:hover{
			background: darken(@base, 20%);
			color: #fff;
			border:2px solid @base;
		}
	}
}
.btn-success{ 
	background: @green;
	&:hover{
		background: #81bb1b;
		color: #fff;
	}
	&.btn-border{
		color: @green;
		background: none;
		border:2px solid @green;
		&:hover{
			background: darken(@green, 20%);
			color: #fff;
			border:2px solid @green;
		}
	}
}

.btn-info{ 
	background: @info;
	&:hover{
		background: #669def;
		color: #fff;
	}
	&.btn-border{
		color: @info;
		background: none;
		border:2px solid @info;
		&:hover{
			background: darken(@info, 20%);
			color: #fff;
			border:2px solid @info;
		}
	}
}

.btn-warning{ 
	background: @orange;
	&:hover{
		background: #ee791b;
		color: #fff;
	}
	&.btn-border{
		color: @orange;
		background: none;
		border:2px solid @orange;
		&:hover{
			background: darken(@orange, 20%);
			color: #fff;
			border:2px solid @orange;
		}
	}
}
.btn-danger{ 
	background: @danger;
	&:hover{
		background: #ec5c5c;
		color: #fff;
	}
	&.btn-border{
		color: @danger;
		background: none;
		border:2px solid @danger;
		&:hover{
			background: darken(@danger, 20%);
			color: #fff;
			border:2px solid @danger;
		}
	}
}

.btn-xs{
	padding: 5px 10px;
	font-size: 12px;
}
.btn-sm{
	padding: 8px 15px;
	font-size: 14px;
}
.btn-lg{
	padding: 20px 25px;
	font-size: 16px;
}


.btn-more{
	color: #fff; 
	padding: 10px 14px 10px 24px;
	border-radius: 0;
	background: @base;
	text-transform: uppercase;
	&:after{
		font-family: FontAwesome;
		content: "\f054";
		display: inline-block;
		margin-left: 15px;
		font-size: 12px;
		color: #fff;
	}
	&:hover{
		color: #fff;
		text-decoration: none;
		background: darken(@base, 20%);
	}
}

.btn-contact{
	display: inline-block;
	background: @base;
	color: #fff;
	width: 80px;
	height: 80px;
	.border-radius(100px);
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	position: absolute;
	left: 50%;
	margin-left: -40px;
	margin-top: -40px;
	z-index: 100;
	&:hover,
	&:focus{
		color:#fff;
	}

}