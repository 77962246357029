/**
 03.3 Facts
**/

#facts{
    text-transform: uppercase;
    h3{
        font-size: 40px;
        .lato;
        margin-bottom: 10px;
    }
    .fact-detail{
        .fact-icon{
            background: @black;
            width: 120px;
            height: 120px;
            display: inline-block;
            margin-bottom: 20px;
            .border-radius(120px);
            i{line-height: 120px;font-size: 45px;}
        }
    }
} 