.bg-grey{
    background: @gray;
}
.bg-orange{
    background: @base;
    color: #fff;
    .opensans;
    .btn-primary{
        background: @black;
        color: #fff;
        border:0;
        &:hover{ 
            border:0;
            background-color: darken(@black, 10%) 
        }
    }
}