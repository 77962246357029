/**
 02.5 Header
**/
header{
	background: @black;
	color: @white;
	.raleway; 
	z-index:9999;
	.navbar-default{
		background: @black;
		border:0;
	}
	.container{
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.navbar-default .navbar-brand{
		color: @white !important;
		padding: 5px 15px;
		img{
			max-width: 200px;
		}
	}
	#mainmenu{
		.nav{
			li{
				text-transform: uppercase;
				letter-spacing: 1.5px;
				margin-left: 10px; 
				font-size: 14px;
				a{
					color: @white;
					.transition;
					&:hover,
					&:active,
					&:focus{
						background: @defaultColour;
						color: @white;
					}
				}
				&.active a,
				&.active a:focus,
				&.active a:active{
					background: none;
					color: @defaultColour;
				}
				&.dropdown{
					&.open{
						> a{
							background: @defaultColour;
							color: @white;
						}
					}
					ul.dropdown-menu{
						margin:10px 0;
						border:0;
						background: @black;
						li{
							margin: 5px 0;
							a{
								color: @white;
								text-transform: capitalize;
								padding: 10px 20px;
								letter-spacing: normal;
								&:hover,
								&:focus{
									color:@white;
								}
							}
						}
					}
				}
			} 
		}
	}
}
@media(max-width: 1199px){
	.navbar-header{
		float: none;
	}
	#mainmenu{ display: none;}
	header{
		.navbar-default .navbar-toggle{
			border:0;
			display: block;
			float: right;
			color: #fff;
			span{
				font-size: 25px;
			}
			&:hover,
			&:focus{
				background: none;
			}
			.fa:before{
				content: "\f00d";
			}
			&.collapsed{
				.fa:before{
					content: "\f0c9";
				}
			}
			
		}
		.navbar-default .navbar-collapse{
			display: none !important;
		}
	}
}




.main-title{
	padding: 0 15%;
	margin-bottom: 50px;
	h2{text-transform: uppercase;}
}