/**
 02.3 Footer
**/

#footer{
    background-color: #202020;
    padding: 80px 0 50px 0;
    color: #646464;
    .col-md-3{
        padding-bottom: 30px;
    }
    h4{ text-transform: uppercase; font-size: 16px;}
    a{
            color: #646464;
            &:hover{
                color:@base;
                text-decoration: none;
            }
        }
    ul.list-unstyled{
        li{
            border-bottom:1px solid #353535;
            a{
                color: #dedede;
                padding: 10px 0;
                display: block;
                &:hover{
                    color:@base;
                    text-decoration: none;
                }
            }
        }
    }
    .copyright{
        background: #121212;
        color: #646464;
        padding: 30px 0;
        a{
            color: #646464;
            &:hover{
                color:@base;
                text-decoration: none;
            }
        }
    }
}