
/**
 03.9 Contact
**/

.contact-collapse{
  text-align: center;
  position: relative;
  z-index: 10;
  width: 100%;
  display: block; 
}

.contact-form{
   .form-group{
    margin-bottom: 20px;
   }
   .form-control{
    padding: 10px 20px;
    height: 45px;
   }
   textarea.form-control{
    height: 245px;
   }
   .btn{
    width: 100%;
    text-align: center;
   }
   h4{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
   }
   p{
    margin-bottom: 30px;
   }
}
