/**
 03.8 Map
**/
#map {
    display: block;
    width: 100%;
    height: 400px;
    position: relative;
}
#google-container {
    position: relative;
    min-width: 100%;
    min-height: 400px;
    background-color: #e7eaf0;
}

#cd-zoom-in, #cd-zoom-out {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-left: 10px;
    background-color: @defaultColour;
    background-repeat: no-repeat;
    background-size: 32px 64px;
    background-image: url("../images/cd-icon-controller.svg");
}
#cd-zoom-out {
    background-position: 50% -32px;
}
#cd-zoom-in {
    background-position: 50% 0;
    margin-top: 10px;
    margin-bottom: 1px;
}

