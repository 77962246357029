/**
 01.2 Forms
**/

input,
input[type="text"],
input[type="email"],
textarea,
.form-control{
    border-radius:0;
}
