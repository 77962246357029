/**
 03.4 Projects
**/
.project-filter{
    text-align: center;
    margin: 40px 0;
    text-transform: uppercase;
    list-style: none;
    padding:0;
    li{
        display: inline-block;
        cursor: pointer;
        margin-bottom: 10px;
        a{
            padding: 5px 10px;
        }
        a:hover,
        a:focus{
            background:@base;
            color: #fff;
            text-decoration: none;
        }
        &.active{
            background: @base;
            color: #fff
        }
    }
}

#project-grid{
    overflow: hidden;
    margin-bottom: 100px;
    .item{
        width: 25%;
        float: left;
        margin: 0;
        //position: relative;
        figure{
            height: 250px; 
            overflow: hidden;
        }
        img{
            width: 100%;
            height: auto;
        }
        @media(min-width: 768px)and(max-width: 992px){
            width: 50%;
        }
        @media(max-width: 767px){
            width: 100%;
        }
    }
}

