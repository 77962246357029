/**
 02.1 Navigation
**/

#navigation-mobile{ 
	overflow-y:scroll;
	height: 358px;
	margin: 0 -15px;
	.navbar-right{
		float: none !important;
		margin:0;
		>li{
			float: none !important;
			border-bottom: 1px solid #3f4f5f;
			>a{
				color: @white;
				&:hover,
				&:focus{
					background: @base;
				}
				.menu-toggler{
					float: right;
				}
			}
			&.active{
				a{
					background: @base;
				}
			}
			.dropdown{
				&.open{

				}
			}
			.dropdown-menu{
				position: relative;
				width: 100%;
				border:0;
				border-radius: 0;
				padding: 0;
				margin: 0;
				>li{
					>a{
						background: @black;
						color: @white;
						padding: 10px 25px;
						&:hover{
							background: @base;
						}
					}
				}
			}
		}
	}
} 