/**
 03.1 Blog
**/
.blog-post{ 
    margin-bottom:40px;
    position: relative;
    .thumb-slider, figure{ 
        margin-bottom: 20px;
        position: relative;
        zoom:1;
    }
    
    .thumb-slider{
        .flex-direction-nav .flex-prev{ 
            left: 0px;
            background: @base;
            text-align: center;
            color:#fff;
            &:before{
                color:#fff;
                display: block;
            }
        }
        .flex-direction-nav .flex-next{ 
            right: 0px;
            background: @base;
            text-align: center;
            color:#fff;
            &:before{
                color:#fff;
                display: block;
            }
        }   
        &:hover .flex-direction-nav .flex-prev {
          opacity: 0.7;
          left: 10px;
        }
        &:hover .flex-direction-nav .flex-prev:hover {
          opacity: 1;
        }
        &:hover .flex-direction-nav .flex-next {
          opacity: 0.7;
          right: 10px;
        }
        &:hover .flex-direction-nav .flex-next:hover {
          opacity: 1;
        }
    }
    
    h4{
        text-transform: uppercase;
        margin: 0;
        a{
            color: #333;
        }
    }
    p{
        margin-bottom: 20px;
    }

    .post-meta{
        font-size: 12px;
        margin-bottom: 15px;
        display: block;
        color: #666;
        overflow: hidden;
        .cats{
            float: right;
            text-transform: uppercase;
        }
    }
    .bx-wrapper{
        margin-bottom: 20px!important;
        .bx-viewport{
            border:0;
            box-shadow: none;
            left: 0;
        }
    }
    .post-slider{
        margin: 0; 
        padding: 0;
        height: 250px;
    }
}
.blog-grid-view{
    list-style: none;
    margin: 0;
    padding: 0;
    .clearfix;
    >li{
        float: left;
        width: 50%;
        padding: 0 15px;
    }
    @media(min-width: 992px)and(max-width: 1199px){
        >li{max-height: 400px;}
    }
    @media(max-width: 991px){
        >li{
            width: 100%;
        }
    }
}
.blog-pager{
    margin-top: 40px;
    .pager{
        li > a{
            background: @black;
            color: #fff;
            border-radius: 0;
            border:0;
            .transition;
            &:hover{
                background: darken(@black, 10%);
            }
        }

        li > a span{
            font-size: 12px;
        }
    }
}
.blog-related{
    .clearfix; 
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            float: left;
            width: 32%;
            margin-left: 15px;
             margin-bottom: 30px;
            figure{
                height: 150px;
                overflow: hidden;
            }
            &:first-child{
                margin-left: 0;
            }
        }
    }
    .post-meta{ 
        font-size: 12px; background: @black; color: #ccc; display: block; padding: 10px;
        a{
            color: #ccc;
        }
    }
    h5{
        font-size: 18px;
        text-transform: uppercase;
    }
     @media(min-width: 992px)and(max-width: 1199px){
        ul{
            li{ 
                width: 31%;
                 figure{
                    height: 120px;
                    overflow: hidden;
                }
            }
        }
    }
    @media(min-width: 768px)and(max-width: 991px){
        ul{
            li{ width: 31.9%}
        }
    }
    @media(max-width: 767px){
        ul{
            li{ 
                width: 100%;
                margin-left: 0;
                figure{
                    height: 150px;
                    overflow: hidden;
                }
            }
        }
    }
}

.admin-info{
    .clearfix;
    background: #e9f0f6;
    padding: 15px;
    margin-bottom: 30px;
    .admin-img{ float: left; width: 20%;}
    .admin-text{ float: left; width: 80%; padding-left: 15px;}
    h4{ margin-bottom: 10px;}
    .list-inline li{ margin-right: 10px;}
    .list-inline i{ margin-right: 10px; color: @base;}
    .admin-social{
        a{
            color: @black;
            .transition;
            i{color: @black;}
            &:hover{
                color: @base;
                i{color: @base;}
            }
        }
    }
    @media(max-width: 767px){
        .admin-img{ float: left; width: 30%;}
        .admin-text{ float: left; width: 70%; padding-left: 15px;}
    }
}

.innerpage-title{
    padding: 40px 0;
    background: @base;
    max-height: 440px;
    padding-top: 100px;
    background: url(../images/pics/pic4.jpeg);
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    h2{
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    p{
        text-transform: uppercase;
    }
}

#blog-area{
    .blog-post .thumb-slider, 
    .blog-post figure{
        max-height: 350px;
        overflow: hidden;
    }
}

.widget{
    margin-bottom: 30px;
    padding-left: 20px;
}
.search-box{
    .form-control{
        border:1px solid @base;
        padding: 10px;
        height: 52px;
        box-shadow: none;
    }
    .input-group-btn{
        background: @base;
        color: #fff;
        .btn-primary{
            border:0;

        }
    }
}
.blog-list{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        clear: both;
        overflow: hidden;
    }
    figure{
        overflow: hidden;
        height: 75px;  
        float: left;
        width: 100px;
        margin-top: 10px;
        margin-right: 10px;
    }
    .post-meta{
        font-size: 13px;
    }
    h5{
        text-transform: uppercase;
        font-size: 16px;
        a{
            color: @black;
        }
    } 
}
.categories-list{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        border-bottom: 1px solid #eee;
        text-transform: uppercase;
        a{
            display: block;
            padding: 10px 0;
            span{
                float: right;
                color: #fff;
                background: @base;
                font-size: 13px;
                min-width: 25px;
                height: 25px;
                text-align: center;
                .border-radius(20px);
            }
        }
    }
}
.tags{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        display: inline-block;
        margin-bottom: 5px;
        margin-right: 5px;
        text-transform: uppercase;
        >a{
            background: @base;
            color: #fff;
            padding: 5px 10px;
            display: block;
            text-decoration: none;
            font-size: 14px;
            .transition;
            &:hover{
                background: darken(@base, 20%);
                text-decoration: none;
            }
        }
    }
}






.comments-area{
    label{
        display: block;
    }
    p{
        margin-bottom: 20px;
    }
    .form-control{
        padding: 20px;
    }
}

.comment-list{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
        .clearfix;
        margin: 20px 0; 
        &.comment{
            background: #e9f0f6;
            padding: 15px;
        }
    }
    ol{
        list-style: none;
        &.children{
            margin:10px 0;
            .comment{
                background: #f1f9ff;
            }
        }
    }
    .comment-meta{
        figure img{
            width: 100%; height: auto;
        }
    }
    .comment-name{ font-size: 18px;}
    .comment-name,
    .comment-date{ display: block;}
    .comment-date{ font-size: 14px; margin-bottom: 10px; color: #666;}
    .comment-body{
        float: left;
        width: 15%;
    }
    .comment-content{
        float: right;
        width: 83%;
    }
    .reply{
        clear: both;
        text-align: right; 
        //border-bottom: 1px solid #fff;
    }
}



