/**
 05. Media Queries
**/
@media(min-width: 992px)and(max-width: 1199px){
	#project-grid{
		.mix{
	        figure{
	            height: 230px; 
	        }
    	}
	}	
}


@media(min-width: 768px)and(max-width: 991px){
	header .navbar-default .navbar-toggle{ margin-right: 0;}
	#hero{
		.heroslider{
			height: 500px;
			figure{
				height: 500px;
			}
			.hero-text{ 
				top: 100px; 
				h1,h2,p{
					max-width: 100%;
				}
			}
		}
	}
	#project-grid{
		.mix{
			width: 50%;
	        figure{
	            height: 250px; 
	        }
    	}
	}	
	.widget{ padding-left: 0;}
}

@media(max-width: 420px){
	#project-grid{
		.mix{
			width: 100%;
	        figure{
	            height: 250px; 
	        }
    	}
	}
}
@media(min-width: 421px)and(max-width: 520px){
	#project-grid{
		.mix{
			width: 50%;
	        figure{
	            height: 180px; 
	        }
    	}
	}
	figure.zoom-in .icon{
		top:38%;
	}
}
@media(min-width: 521px)and(max-width: 767px){
	#project-grid{
		.mix{
			width: 50%;
	        figure{
	            height: 200px; 
	        }
    	}
	}
	figure.zoom-in .icon{
		top:38%;
	}
}

@media(max-width: 767px){
	header .container{
		margin-top:10px;
		margin-bottom:0px;
	}
	.navbar{
		margin-bottom: 0;
	}
	.navbar-toggle{ margin-top: 0;}
	header .navbar-default .navbar-brand img{ max-width: 160px; }
	h2{
		font-size: 25px;
	}
	h3{
		line-height: 30px;
	}
	h4{
		font-size: 16px;
		line-height: 24px;
	}
	#hero{
		margin-top: 34px;
		.heroslider{
			height: 450px;
			.slides img{ 
				width: 300%; 
			}
			figure{
				height: 450px;
				overflow: hidden;
			}
			.hero-text{ 
				top: 50px; 
				h1,h2,p{
					max-width: 100%;
				}
				h1{ font-size: 30px; line-height: 35px;}
				h2{ font-size: 16px;}
				p{ font-size: 14px; max-height: 100px; overflow: hidden;}
			}
			.flex-direction-nav{ display: none !important; }
		}
		.button-container{
			display: block;
			.btn{
				width: 100%;
			}
		}
	}
	section{ padding: 50px 0;}
	.main-title{
		padding: 0;
		h2{
			line-height: 30px;
		}
	}
	.project-filter{
    	margin: 20px 0;
	} 
	.fact-detail{
		text-align: left;
		min-height: 150px;
		.fact-icon{ 
			float: left; 
			text-align: center;
			margin-right: 20px;
		}
		.fact-number {
			text-align: left;
		}
	}
	.the-team{
		figure.slide-up{
			max-height: none;
			min-width: 200px;
		}
	}
	.call-to-action{
		.action-desc{ 
			width: 100%;
			text-align: center;
			.fa{
				display: block;
			}
		}
		.action-btn{
			width: 100%;
			float: none;
			.btn{
				width: 100%;
			}
		}
	}
	.innerpage-title{
		padding-top: 60px;
	}
	.widget{ padding-left: 0;}
}