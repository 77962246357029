/**
 02.7 Hero
**/
#hero{
	background: @black;
	padding:0;
	margin-top: 60px;
	position: relative;
	overflow: hidden;

	.heroslider{
		margin: 0;
		padding: 0; 
		height: 650px;
		position: relative;
		.flex-direction-nav .flex-prev{ 
			left: 0px; 
			&:before{
				color:#fff;
			}
		}
        .flex-direction-nav .flex-next{ 
        	right: 0px; 
        	&:before{
        		color:#fff;
        	}
        }   
        &:hover .flex-direction-nav .flex-prev {
          opacity: 0.7;
          left: 20px;
        }
        &:hover .flex-direction-nav .flex-prev:hover {
          opacity: 1;
        }
        &:hover .flex-direction-nav .flex-next {
          opacity: 0.7;
          right: 20px;
        }
        &:hover .flex-direction-nav .flex-next:hover {
          opacity: 1;
        }
        .flex-control-nav{
        	bottom: 20px;
        	z-index: 30;
        }
        .flex-control-paging li a{ 
        	
        	background: @white;
        	box-shadow: none;
        	width: 14px;
        	height: 14px;
        	&.flex-active{ background: @base;}
        }
		.slides > li {
		  display: none;
		  position: relative;
		  -webkit-backface-visibility: hidden;
		}
		figure{
			z-index: 5;
			height: 650px;
			position: relative;
		}
		.slides img {
		  width: 100%;
		  opacity: 0.5;
		  display: block;
		}
		.hero-text{
			position: absolute;
			left: 0;
			top: 200px;
			width: 100%;
			z-index: 10;
		}
	}
	.hero-text{
		font-size: 14px;
		line-height: 24px;
		color: #fff;
		.raleway;
		letter-spacing: 1px;
		h1{
			font-size: 40px;
			margin:0 0 10px 0;
			line-height: 42px;
			text-transform: uppercase;
			display: block;
			font-weight: 700;
			letter-spacing: 2px;
			max-width: 70%;
			span{
				color: @base;
			}
		}
		h2{
			font-size: 20px;
			margin:0;
			display: block;
			max-width: 70%;
			&.tagline{
				border:2px solid #fff;
				font-size: 16px;
				text-transform: uppercase;
				padding: 12px 15px 10px 15px;
				display: inline-block;
				margin:0 0 20px 0;
				line-height: 16px;
			}
			&:after{ display: none;}
		}
		p{
			margin-bottom: 30px;
			display: block;
			line-height: 24px;
			max-width: 50%;
		}
		&.text-center{
			h1,h2{ margin-left:auto; margin-right: auto;}
		}
	}
	.button-container{
		display: block;
		.btn{
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}
} 


