/**
 03.6 Testimonial
**/
.testimonial{
    clear: both;
}
#testimonial-slide{   
    position: relative;
    zoom:1;
    text-align: center; 
        .flex-direction-nav .flex-prev{ left: 0px;}
        .flex-direction-nav .flex-next{ right: 0px;}    
        &:hover .flex-direction-nav .flex-prev {
          opacity: 0.7;
          left: 10px;
        }
        &:hover .flex-direction-nav .flex-prev:hover {
          opacity: 1;
        }
        &:hover .flex-direction-nav .flex-next {
          opacity: 0.7;
          right: 10px;
        }
        &:hover .flex-direction-nav .flex-next:hover {
          opacity: 1;
        }
   
   .flex-control-paging li a{  
          background: @black;
          box-shadow: none;
          width: 14px;
          height: 14px;
          &.flex-active{ background: @base;}
        }
    p{ margin-bottom: 20px;}
    figure{
        overflow: hidden;
        width: 80px;
        height: 80px;
        margin: 10px auto;
        .border-radius(80px);
        img{
            display: inline;
            width: 100%;
            height: auto;
        }
    }
}