/**
 05.1 Misc
**/
.go-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    display: none;
    width: 50px;
    height: 50px;
    a{
        display: block;
        background: @black;
        color: #fff;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        &:hover{ opacity: 0.8;}
    }
}

.icon-animated {
    position: relative;
    z-index: 1;
}

.icon-animated:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box; 
    -moz-box-sizing: content-box; 
    box-sizing: content-box;
}
 

.icon-effect .icon-animated {
    background: @base;
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
}

.icon-effect .icon-animated:after {
    top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 4px @base;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(.8);
    opacity: 0;
}


.icon-effect:hover .icon-animated {
    background: @base;
    color: #fff;
}

.icon-effect:hover .icon-animated:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}


figure.zoom-in{
   position: relative;
   overflow: hidden;
   -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
   figcaption,
   figcaption > a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
   }
}


figure.zoom-in {
    background: @black;
   
}

figure.zoom-in img {
    opacity: 0.9;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;

}

figure.zoom-in figcaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 2px solid @white;
    //box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.4,1.4,1);
    transform: scale3d(1.4,1.4,1);
}



figure.zoom-in .icon {
    margin: 0;
    opacity: 0;
    color: #fff;
    font-size: 50px;
    position: relative;
    top: 43%;
    transform: translateY(-50%);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}
 

figure.zoom-in:hover figcaption::before,
figure.zoom-in:hover .icon {
    opacity: 1;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1); 
}

figure.zoom-in:hover figcaption {
    background-color: rgba(44,62,80,0.4); 
}

figure.zoom-in:hover img {
    opacity: 0.4;
}

figure.slide-up{
    position: relative; 
    overflow: hidden;
    margin: 10px 0;
    min-width: 320px;
    max-width: 480px;
    max-height: 300px;
    background: @black;
    text-align: center; 
}
figure.slide-up img{ 
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
    .transition;
}
figure.slide-up figcaption {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    bottom: 0;
    padding: 20px;
    height: auto;
    background: @base;
    text-align: center;
    color: #fff;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
}

figure.slide-up h4 {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 22px;
}
figure.slide-up h4 span{ display: block; font-size: 14px;}
figure.slide-up p.icon-links{ margin-bottom: 0;}
figure.slide-up p.icon-links a {
    color: #fff;
    font-size: 16px;
    margin: 0 10px;
}

figure.slide-up:hover p.icon-links a:hover,
figure.slide-up:hover p.icon-links a:focus {
    color: #252d31;
}
 
figure.slide-up h4,
figure.slide-up p.icon-links a {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,200%,0);
    transform: translate3d(0,200%,0);
}

figure.slide-up p.icon-links a span::before {
    display: inline-block;
    padding: 8px 10px;
    font-family: 'feathericons';
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

figure.slide-up h4 {
    display: inline-block;
}

figure.slide-up:hover p.description {
    opacity: 1;
}
figure.slide-up:hover img{ opacity: 1;}

figure.slide-up:hover figcaption,
figure.slide-up:hover h4,
figure.slide-up:hover p.icon-links a {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.slide-up:hover h4 {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.slide-up:hover p.icon-links a:nth-child(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

figure.slide-up:hover p.icon-links a:nth-child(2) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

figure.slide-up:hover p.icon-links a:first-child {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
