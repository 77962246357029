/**
 03.7 Pricing Box
**/
.pricing-box{
    background: @gray;
    text-align: center; 
    padding-bottom: 30px;
    margin-top: 40px;
    .transition;
    &:hover{ 
        -webkit-box-shadow: 0px 0px 10px 0px rgba(173,173,173,1);
        -moz-box-shadow: 0px 0px 10px 0px rgba(173,173,173,1);
        box-shadow: 0px 0px 10px 0px rgba(173,173,173,1);
    }
    h3{
        background: @black;
        color: #fff;
        padding: 20px;
        margin-bottom: 0;
        text-transform: uppercase;
        &:before{ display:none;}
    }
    .prize{
        font-size: 40px;
        padding-top: 20px;
        padding-bottom: 30px;
        background: @base;
        color: #fff;
        span{
            display: block;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    ul{
        margin:20px 0 60px 0;
        padding: 0;
        li{
            list-style: none;
            margin: 0;
            padding: 10px 0;
        }
    }
    &-action{
        position: absolute;
        left: 0;
        bottom: 0;
        padding:20px;
        width: 100%;
    }
}