/**
 03.3 CTA
**/
.call-to-action{
  .clearfix;
  background: @gray;
  padding: 40px 0;
  .action-desc{
    float: left;
    width: 80%;
    h3{
      margin-bottom: 0;
    }
    p{
      font-style: italic;
      font-size: 20px;
    }
    .fa{
      font-size: 40px;
      margin-right: 10px;
    }
  } 
  .action-btn{
    float: left;
    width: 20%;
  } 
}