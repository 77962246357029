/**
 02.6 Accordion
**/
.accordion{
  border-top: solid 1px #ced1d3;
  margin-bottom: 50px;
  .panel{
    border-radius: 0;
    box-shadow: none;
    border:0;
    border-bottom: solid 1px #ced1d3;
    +.panel{
      margin-top: 0;
    }
  }
  .panel-heading{
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    .raleway;
    text-transform: uppercase;
    a{
      display: block;
      padding: 15px 30px;
      color: @black;
      background: #e9f0f6;
      text-decoration: none;
      position: relative;
      span.fa{
        position: absolute;
        right: 20px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        background-color: rgba(14, 34, 42, 0.1);
        &:before{
            content: "\f068";
          }
      }
      &.collapsed{
        background: #e9f0f6;
        span.fa{
          &:before{
            content: "\f067";
          }
        }
      }
      &:hover{
        background: #f1f9ff; 
      }
      &:hover, 
      &:focus{ text-decoration: none;}
    }
    
  }
  .panel-body{
    border-top: 0;
    padding: 0px 30px 30px 30px;
    background-color: #e9f0f6;
    figure{
      float: right;
      margin: 0 0 30px 50px;
    }
    p{
        margin-bottom: 20px;
    }
  }
  .panel-heading+.panel-collapse>.list-group, 
  .panel-heading+.panel-collapse>.panel-body{
    border:0;
  }
}