
/**
 01.1 Typography
**/

body{
  .lato;
  font-size: 15px;
  line-height: 26px;
}

h1{
  font-size: 40px;
  .raleway;
  letter-spacing: 1px;
  line-height: 45px; 
  a{
    color: @black;
  }
}
h2{
  font-size: 30px;
  .raleway;
  letter-spacing: 1px;
  line-height: 60px;
  margin-top:0;
  margin-bottom: 30px;
  text-transform: uppercase;
  &.text-center{
    &:after{
      content:"";
      display: block;
      width: 70px;
      height: 2px;
      margin: 0 auto;
      background: @defaultColour;
    }
  }
  &:after{
    content:"";
    display: block;
    width: 70px;
    height: 2px;
    background: @defaultColour;
  }
  a{
    color: @black;
  }
}
h3{
  font-size: 24px;
  .raleway;
  letter-spacing: 1px;
  line-height: 50px;
  margin-top:0;
  margin-bottom: 20px;
  text-transform: uppercase;
  &.text-center{
    &:after{
      content:"";
      display: block;
      width: 35px;
      height: 2px;
      margin: 0 auto;
      background: @defaultColour;
    }
  }
  &:after{
    content:"";
    display: block;
    width: 35px;
    height: 2px;
    background: @defaultColour;
  }
  a{
    color: @black;
  }
}
h4{
  font-size: 20px;
  .raleway;
  letter-spacing: 1px;
  line-height: 20px;
  margin-top:0;
  margin-bottom: 20px;
  text-transform: uppercase;
}
a{
  .transition;
  color:@defaultColour;
  &:hover{
      color:@defaultColour;
      }
} 


blockquote{
    border-color: @base;  
    padding: 20px;
}